window.scrollTo(0, 0);

window.onorientationchange = function() {
  window.location.reload();
};

if (!window.getComputedStyle) {
    window.getComputedStyle = function(el, pseudo) {
        this.el = el;
        this.getPropertyValue = function(prop) {
            var re = /(\-([a-z]){1})/g;
            if (prop == 'float') prop = 'styleFloat';
            if (re.test(prop)) {
                prop = prop.replace(re, function () {
                    return arguments[2].toUpperCase();
                });
            }
            return el.currentStyle[prop] ? el.currentStyle[prop] : null;
        }
        return this;
    }
}

var media_width, media_height;
var isMacLike = navigator.platform.match(/(Mac|iPhone|iPod|iPad)/i)?true:false;
var isIOS = navigator.platform.match(/(iPhone|iPod|iPad)/i)?true:false;
var isTouchDevice = 'ontouchstart' in document.documentElement;
var tablet_width = 1024;
var mobile_width = 768;


var homeSlider;
var collectionSlider;
var innerSlider;

var is_scrollbar_init = false;
//var infobox_top = 0;

$(document).ready(function () {

  media_width = $(window).width();
  media_height = $(window).height();

  // $('.navbar').on('show.bs.collapse', function () {
  //   var actives = $(this).find('.collapse.in'),
  //       hasData;

  //   if (actives && actives.length) {
  //       hasData = actives.data('collapse')
  //       if (hasData && hasData.transitioning) return
  //       actives.collapse('hide')
  //       hasData || actives.data('collapse', null)
  //   }
  // });
  //   $('#latest-news').css('top', $('.navbar').outerHeight());
  //
  //   var news_height =
  //   $('#latest-news ul.news').css('height', news_height);
  //   height: 300px;
  //   overflow:hidden; overflow-y:scroll;
  //
  //   $( window ).resize(function() {
  //       media_width = $(window).width();
  //       media_height = $(window).height();
  //       $('#latest-news').css('top', $('.navbar').outerHeight());
  //   });

  var homeSlider = new Swiper ('.home-slider', {
    direction: 'horizontal',
    loop: true,
    speed: 2000,
    effect: 'fade',
    autoplay: 3000,
    onSlideChangeStart: function(swiper) {
      var swiperPage = swiper.activeIndex;

      // $('ul#menu > li a').removeClass('active');
      // $('ul#menu > li').eq(swiperPage-1).find('a').addClass('active');

    }

  });

  var collectionSlider = new Swiper ('.collection-slider', {
    direction: 'horizontal',
    loop: true,
    speed: 2000,
    effect: 'slide',
    autoplay: false,
    nextButton: '.swiper-button-next',
    prevButton: '.swiper-button-prev',
  });

    $('#latest-news h2').click(function() {
        $('#latest-news').toggleClass('active');
    });

    $("[data-toggle=popover]").popover({
    html: true, 
    content: function() {
          return $('#popover-content').html();
        }
});



 $('#origin').tooltipster({
    // we detach the element from the page and give it to Tooltipster to serve as content
    content: $('#tooltip_content').detach(),
    // if you use a single element as content for several tooltips, set this option to true
    contentCloning: false,
    contentAsHTML: true,
    trigger: 'click',
    interactive: true
});
  $('#origin2').tooltipster({
    // we detach the element from the page and give it to Tooltipster to serve as content
    content: $('#tooltip_content2').detach(),
    // if you use a single element as content for several tooltips, set this option to true
    contentCloning: false,
    contentAsHTML: true,
    trigger: 'click',
    interactive: true
});


$('#image-gallery').lightSlider({
    gallery:true,
    item:1,
    thumbItem:3,
    slideMargin: 0,
    speed:500,
    auto:true,
    loop:true,
    onSliderLoad: function() {
        $('#image-gallery').removeClass('cS-hidden');
    }  
});


});